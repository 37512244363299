<template>
  <cs-page class="views-learn-index">
    <cs-banner :type="6" />
    <div class="d-flex mx-auto" style="width: 1217px; margin-top: 60px">
      <OrgTree @search="getTreeItem" :active="formModel.studyOrganId"/>
      <div class="learn-panel">
          <a-input ref="titleInput" v-model.trim="formModel.title" placeholder="请输入" style="max-width: 650px;" @keyup.enter="search" >
            <a-tooltip slot="suffix" @click="search">
              <cs-icon name="icon-sousuo" size="22" style="cursor:pointer;color:#333"/>
            </a-tooltip>
          </a-input>
          <div class="d-flex" style="margin: 20px 0;color: #949898;">
            <span style="width: 80px;">当前分类：</span>
            <a-breadcrumb separator=">" style="flex:1;">
              <a-breadcrumb-item v-for="(item, index) in source.nav" :key="index" style="color: #949898;">
                <a href="javascript:;" @click="getTreeItem(item)">{{item.name}}</a>
              </a-breadcrumb-item>
            </a-breadcrumb>
          </div>
          <div class="list-content">
            <a-spin
              tip="数据加载中..."
              :spinning="loading"
            >
            <template v-if="source.list.length">
              <a-row :gutter="[30,30]" class="content">
                <a-col class="gutter-row" :span="8" v-for="(item, index) in source.list" :key="index">
                  <a :href="`details?id=${item.id}`" target="_blank" class="box" style="background:#fff;display:block;">
                    <img :src="item.cover" class="imgs"/>
                    <div class="title">{{item.title}}</div>
                    <div style="margin: 0 16px;"><span class="type-text">{{item.studyOrganName}}</span><span class="tag-text"  :class="item.type === 1 ? 'c-yellow' :'c-blue' ">{{item.type === 1 ? '#文档':'#视频' }}</span></div>
                  </a>
                </a-col>
              </a-row>
              <div class="pagination-wrap">
                <a-pagination
                  show-quick-jumper
                  style="text-align: center"
                  :current="pagination.page"
                  :pageSize="pagination.size"
                  :total="pagination.total"
                  @change="currentChange"
                  @showSizeChange="sizeChange"
                />
              </div>
            </template>
            <template v-else>
              <a-empty :image="require('../../../public/img/暂无资料@2x.png')" style="padding-top: 160px">
                <span slot="description">暂无资料</span>
              </a-empty>
            </template>
            </a-spin>
          </div>
      </div>
    </div>
  </cs-page>
</template>
<script>
import { components } from "@/plugins/utils";
export default {
  ...components({
    learn: ["OrgTree"],
  }),
  data() {
    return {
      formModel: {
        title: '',
        studyOrganId: 0
      },
      pagination: {
        page: 1,
        size: 9,
        total: 0,
      },
      source: {
        nav: [],
        list: []
      },
      loading: false,
      cancelToken: null
    };
  },
  created() {
  },
  methods: {
    getTreeItem({id}) {
      this.formModel.studyOrganId = id
      this.pagination.page = 1
      this.initData()
    },
    search() {
      if (!this.formModel.title) return
      this.pagination.page = 1
      this.initData()
    },
    initData() {
      // 如果当前还在请求状态，则会取消当前请求（需要手动配置）
      if (this.cancelToken && this.cancelToken.cancel) {
        this.cancelToken.cancel("取消请求");
        setTimeout(() => {
          this.loading = false;
          this.getData();
        }, 100);
      } else {
        this.loading = false;
        this.getData();
      }
    },
    getData() {
      if(this.loading) return;
      this.loading = true;
      this.cancelToken = this.api.http.cancelTokenSource();
      this.api.service
        .fpc_oapi_study_file_selectStudyFilePage(
          {
            pageNum: this.pagination.page,
            pageSize: this.pagination.size,
            ...this.formModel
          },
          {},
          {
            cancelToken: this.cancelToken.token
          }
        )
        .then(async ({ code, data, msg }) => {
          this.loading = false
          await this.api.toast({ code, msg }, 0);
          this.source.nav = data.organList
          const { content, totalSize } = data.page;
          this.source.list = content.map((ele) => {
            if (ele.studyOrganName.length > 10) {
              ele.studyOrganName = ele.studyOrganName.slice(0,10)+'...'
            }
            if (ele.title.length > 35) {
              ele.title = ele.title.slice(0,35)+'...'
            }
            return ele
          })

          this.pagination.total = totalSize
        })
        .finally(() => {
          this.loading = false
          this.cancelToken = null;
        });
    },
    currentChange(val) {
      this.pagination.page = val
      this.initData()
    },
    sizeChange(page, size) {
      this.pagination.page = 1
      this.pagination.size = size
      this.initData()
    }
  }
};
</script>
<style lang="less" scoped>
.views-learn-index {
  .learn-panel {
    margin-left: 30px;
    width: 927px;
    .ant-input-affix-wrapper {
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.05);
      border-radius: 10px;
      /deep/ .ant-input {
        border-radius: 10px;
        border: 0;
      }
    }
    .list-content {
      .content {
        min-height: 590px;
        display: flex;
        flex-wrap: wrap;
      }
       .imgs {
        width: 100%;
        height: 160px;
      }
      .title {
        margin: 12px 16px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #202121;
        line-height: 20px;
        height: 40px;
        display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      }
      .type-text {
        background-image: linear-gradient(90deg, #00C7C8 0%, #52D1CB 100%);
        font-family: PingFangSC-Regular;
        max-width: 140px;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 12px;
        margin-right: 8px;
        padding: 2px 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .tag-text {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        line-height: 12px;
        display: inline-block;
        padding: 4px 8px;
        text-align: center;
        &.c-yellow {
          background: #fcf4e7;
          color: #e59317;
        }
        &.c-blue {
          background: #e9f4fd;
          color: #2b94ed;
        }
      }
      .box {
        border-radius: 4px;
        padding-bottom: 20px;
        overflow: hidden;
      }
      .ant-col {
        transition: 0.5s;
        width: 314px;
         .box:hover {
           transition: 0.5s;
           margin-top: -2px;
           box-shadow: 0 7px 15px 0px #bfbfbf;
         }
      }
    }
    /deep/ .ant-input {
      padding: 4px 24px;
      padding-right: 50px;
      height: 57px;
    }
    /deep/ .ant-empty-image {
      height: 200px;
    }
    /deep/ .ant-empty-description {
      font-size: 16px;
      color: #4f4f4f;
    }
    /deep/ .ant-input-affix-wrapper .ant-input-suffix {
      right: 24px;
    }
    .pagination-wrap {
      margin-top: 30px;
      /deep/ .ant-pagination-item,
      /deep/.ant-pagination-prev,
      /deep/ .ant-pagination-next {
        min-width: 40px;
        height: 40px;
        line-height: 40px;
        margin-right: 10px;
      }
      /deep/ .ant-pagination-disabled .ant-pagination-item-link,
      /deep/ .ant-pagination-item,
      /deep/ .ant-pagination-next .ant-pagination-item-link,
      /deep/.ant-pagination-options-quick-jumper input {
        border-color: #eeeeee;
      }
      /deep/ .ant-pagination-item {
        &:hover {
          background: #00A497;
          a {
            color: #fff;
          }
        }
        a {
          color: #202121;
        }
      }
      /deep/ .ant-pagination-item-active {
        background: #00A497;
        a {
        color: #fff;
        }
      }
      /deep/ .ant-pagination-prev a, /deep/ .ant-pagination-next a {
        color: #202121;
      } 
      /deep/ .ant-pagination-options-quick-jumper {
        height: 40px;
        line-height: 40px;
        input {
          height: 40px;
        }
      }
    }
  }
}
</style>