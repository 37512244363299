// 加载组件
export function components(obj) {
    const components = {}
    Object.keys(obj).forEach(name => {
        const value = obj[name]
        if (Array.isArray(value)) {
            value.forEach(key => {
                components[key] = function () {
                    return import(`@/components/${name}/${key}`)
                }
            })
        } else {
            components[name] = value
        }
    })
    return {
        components
    }
}